  <template>
  <div>
    <nav-bar>
      <template v-slot:left>
        <div class="logo" @click="toIndex">
          <nav-bar-logo font="font-size: 26px;"/>
        </div>
      </template>
      <template v-slot:right>
        <div class="navbar-r"><router-link to="/index">{{$store.getters.getSiteInfo.name}}首页</router-link></div>
      </template>
    </nav-bar>
    <div class="user-layout">
      <div class="register-body">
        <div class="title" ><router-link to="/index">重置密码</router-link></div>
        <sign-up-input :numOrSms="false" @form-data="formData" @get-code="getCode" />
        <!-- 立即注册 -->
        <div class="btn-box">
          <el-button class="btn" @click="xiabu">下一步</el-button>
        </div>
        <!-- 立即登录 -->
        <div class="gologin">
          已有账号， <router-link to="/login">立即登录？</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar.vue'
import NavBarLogo from 'components/navbar/NavBarLogo.vue'
import SignUpInput from 'components/signUpInput/chongzhiinput.vue'
import { getCode, chongzhi } from '../../network/api'

export default {
  components: {
    NavBar,
    NavBarLogo,
    SignUpInput // 引入短信
  },
  data () {
    return {
      getFormData: {
        account: ''
      }
    }
  },
  methods: {
    toIndex () {
      this.$router.push('index')
    },
    formData (value) {
      this.getFormData = value
    },
    getCode (value) {
      getCode(this.getFormData.account, 'rePwd').then(res => {
        this.$message({
          message: res.msg,
          type: 'success'
        })
      })
    },
    // 下一步
    xiabu () {
      if (this.getFormData.password === this.getFormData.quepassword) {
        chongzhi(this.getFormData.account, this.getFormData.password, this.getFormData.code).then(res => {
          if (res.code === 0) {
            this.$message.error(res.msg)
          } else if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            const _this = this
            // 重置成功跳转到登录页
            setTimeout(function () {
              _this.$router.push('/login')
            }, 1500)
          }
        })
      } else {
        this.$message.error('密码不一致')
      }
    }
  }
}
</script>

<style scoped src="styles/views/rePwd/rePwd.css">
</style>
